import React from 'react';
import style from './../styles';
import checkMobile from "./../libs/checkMobile";
const isMobile = checkMobile();

const dayStyles = {
  float: 'left',
  width: '19.8%',
  textAlign: 'center',
  border: '1px solid #eef2fa',
  minWidth: '19%',
  minHeight: '100vh',
};
if (isMobile) {
  dayStyles.width = '19.2%';
}

export default ({ day, date, children }) => (
  <div style={dayStyles}>
    <div className="day-header" style={style.DayHeader}>
      <div>{day}</div>
      <div>{date}</div>
    </div>
    {children}
  </div>
);
