import React, { useState } from "react";
export default function ({ autoCompleteData = [], onChange }) {
  const [ showInput, setshowInput ] = useState(false);
  const [ value, setValue ] = useState('');

  let autocomplete = [];
  if (value) {
    autocomplete = autoCompleteData.filter(a => {
      return a.toLowerCase().match(value.toLowerCase());
    });
  }

  console.log({autocomplete});
   
  return (
    <div className="search">
      {showInput ?
        <div>
          <input
            className="search-bar"
            onChange={({ target: { value } }) => setValue(value)}
            placeholder="AAPL"
          ></input>
          {autocomplete ? 
            <ul className="autocomplete">
              {autocomplete.map(a => (
                <li
                  className="autocomplete-item"
                  onClick={() => onChange(a)}
                  key={a}
                >
                  {a}
                </li>
              ))}
            </ul>
          : null }
        </div>
      : 
        <img
          onClick={() => setshowInput(true)}
          style={{width: 20, cursor: 'pointer' }} alt="search" src="/assets/search-icon.svg"
        />
    
      }
    </div>
  );
};
