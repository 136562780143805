import React, { useState } from "react";
import newrelic from './../libs/newrelic';
import styles from './../styles';

const Image = ({src, ticker}) => {
  const [ url, setUrl ] = useState(src);
  const fallbackImg = `https://plchldr.co/i/60x40?&bg=0574b9&fc=fff&text=${ticker}`

  return <img
    src={url}
    alt={`Company logo for ${ticker}`}
    className="logo"
    onError={() => {
      setUrl(fallbackImg)
      newrelic.addPageAction('MissingLogo', { ticker });
    }}
  />;
}

export default ({ ticker, logo = '', width, setShowDetails, details, empty, showDetails }) => {
  if (empty) {
    return (
      <li style={styles.Item}>
        <div style={styles.LogoContainer}>
        </div>
      </li>
    );
  }
  return (
    <li style={styles.Item}>
      <div>
        <div style={styles.LogoContainer}>
          <Image
            className="logo" 
            ticker={ticker}
            src={logo.replace('/assets/images/', '/assets/images/')}
          />
        </div>
        {!showDetails ?
          <div style={styles.ItemDetails}>
            <div>{ticker}</div>
          </div>
        :
        <div style={styles.ItemDetails}>
            <span>{ticker}</span>
          </div>
        }
      </div>
    </li>
  );
};
