import React, { useState } from 'react';
import constants from '../constants';
import styles from './../styles';

const {
  TEXT
} = constants;

export default  ({ currentDate, zoom, onWeekClick, onSortClick, allowSort }) => {
  const [ showControls, setControl] = useState(false);
  const [ selectedSort, setSelectedsort ] = useState('rank');

  const handleSortClick = (value) => {
    onSortClick(value);
    setSelectedsort(value);
  }

  return (
    <div className="controls">
      <button
        className="prev-week-button"
        key="prev" onClick={() => onWeekClick(currentDate.subtract(1, 'week').startOf('isoWeek'))}
      >
        {TEXT.PREVIOUS_BUTTON}
      </button>

      <button style={styles.SortButton} onClick={() => setControl(!showControls)}>Sort</button>

      <button
        className="next-week-button"
        key="next" onClick={() => onWeekClick(currentDate.add(1, 'week').startOf('isoWeek'))}
      >
        {TEXT.NEXT_BUTTON}
      </button>
      {showControls ? 
        <div style={styles.SortControls}>
          Sort By:
          <ul style={{ marginLeft: -30 }}>
            <li
              style={selectedSort === 'rank' ? styles.SelectedSortControlItem : styles.SortControlsItem}
              onClick={() => handleSortClick('rank')}
            >
              Popularity
            </li>
            <li
              style={selectedSort === 'currentMarketcap' ? styles.SelectedSortControlItem : styles.SortControlsItem}
              onClick={() => handleSortClick('currentMarketcap')}
            >
              Market Cap
            </li>
            <li
              style={selectedSort === 'currentPrice' ? styles.SelectedSortControlItem : styles.SortControlsItem}
              onClick={() => handleSortClick('currentPrice')}
            >
              Price
            </li>
            <li
              style={selectedSort === 'eps' ? styles.SelectedSortControlItem : styles.SortControlsItem}
              onClick={() => handleSortClick('eps')}
            >
              EPS
            </li>
          </ul>
          <div style={{clear:'both'}} />
        </div>
      : null }
    </div>
  );
};