import bulletTrain from 'bullet-train-client';
import { set } from './featureFlags';

/***
Can be called both before or after you're done initialising the project.
Calling identify before will prevent flags being fetched twice.
***/
// bulletTrain.identify("development_user_123456"); // This will create a user in the dashboard if they don't already exist

// Standard project initialisation
bulletTrain.init({
    environmentID: "hWsZSAbn8PRwqzxVFcui4Y",
        onChange: (oldFlags, params) => { // Occurs whenever flags are changed
          set('beta', bulletTrain.hasFeature('beta'));
    }
});