import React from 'react';

const styles = {
  Header: {
    height: 35,
    backgroundColor: 'rgb(36 96 132)',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
}

export default ({ children }) => {
  return (
    <div style={styles.Header}>
      {children}
    </div>
  );
};
