import axios from 'axios';
import { useEffect, useState } from "react";

// const API_URL = '/data/earnings.json';

const transformData = (data = []) => {
  return data.reduce((acc, cur) => {
    if (!acc[cur.earningsDate]) {
      acc[cur.earningsDate] = [];
    }
    acc[cur.earningsDate].push(cur);
    return acc;
  }, {}) || [];
};

export default (date, setLoading) => {
  
  const [ earnings, setData ] = useState(null);
  const [ error, setError ] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(`/data/date/${date}.json`);
        setData(
          transformData(data)
        );
        setLoading(false);
        setError(false);
      } catch(e) {
        setError(true);
      }

    };
    setLoading(true);
    getData();
  }, [ date, setLoading ]);

  return [ error, earnings ];
}