import React, { useState } from "react";
import moment from "moment";

// initialize feature flags and tracking
import './libs/bulletTrain';
import './libs/track';

import { isBeta } from './libs/featureFlags';
import getParameterByName from './libs/getParameterByName';

// components
import Calendar from './components/Calendar';
import Controls from './components/Controls';
import Day from './components/Day';
import Item from './components/Item';
import Search from './components/Search';
import Loading from './components/Loading';
import Header from './components/Header';
import Week from './components/Week';

// hooks
import useUpcomingEarnings from './hooks/useUpcomingEarnings';

// styles
import styles from './styles';


const buildRoW = (date) => ({
  date: date.format("MM/DD"),
  day: date.format("dddd"),
  key: date.format("YYYY-MM-DD"),
});


export default () => {
  const dateQueryParam = getParameterByName('date');
  let queryParamMoment;
  if (dateQueryParam) {
    queryParamMoment = moment(dateQueryParam);
  }

  const [ selectedWeek, setWeek ] = useState(queryParamMoment);
  const [ isLoading, setLoading ] = useState(false);
  const [ searchText, setSearchText ] = useState('');

  let current = moment();
  if (current.day() === 0 || current.day() === 6) {
    current = current.add(1, 'day');
  }

  const date = (selectedWeek || current).startOf('isoWeek').format("YYYY-MM-DD");
  const [ error, events ] = useUpcomingEarnings(date, setLoading);
  const [ zoom, setZoom ] = useState(styles.imgWidth);
  const [ sortCriteria, setSortCriteria ] = useState('rank');
  const [ showDetails, setShowDetails ] = useState(true);

  const autoCompleteData = [
    'AAPL',
    'PTON',
    'TSLA',
    'PINS',
    'WKHS'
  ];


  const handleWeekClick = (data) => {
    setLoading(true);
    
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('date', data.format('YYYY-MM-DD'));
    const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);

    setWeek(data.clone());
  }

  const handleZoomChange = (value) => {
    setZoom(value);
  };

  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  const handleSortClick = (value) => {
    setSortCriteria(value);
  };

  const startDate = selectedWeek || current.startOf('isoWeek');
  const headingDate = startDate.format('MMMM Do, YYYY');
  const days = [buildRoW(startDate)];

  for (let i = 1; i < 5; i++) {
    const day = startDate.add(1, 'day')
    days.push(buildRoW(day));
  }
  
  const showLoading = !error && (isLoading || Object.keys(events || {}).length === 0);
  const dates = [];
  for (let i = -5; i < 15; i++) {
    dates.push(moment().startOf('isoWeek').add(i, 'week').format('YYYY-MM-DD'));
  }
  return (
    <div>
      <Header>
      { isBeta() ? 
        <Search
          autoCompleteData={autoCompleteData}
          onChange={handleSearchChange}
        /> : null }
      </Header>

      <div style={{marginTop: 35}}>
        {searchText ? 
          <div>{searchText}</div>
        : 
          <Calendar 
            headingDate={headingDate}
            dates={dates}
            selectedDate={startDate.startOf('isoWeek').format('YYYY-MM-DD')}
            onWeekSelect={handleWeekClick}
          >
            <Controls
              currentDate={startDate} 
              allowSort={isBeta()}
              onSortClick={handleSortClick}
              onWeekClick={handleWeekClick}
              onZoomChange={handleZoomChange}
            />
            <Week>
              {error ? <h3 className="no-data">No earnings data found</h3> : null}
              {showLoading ?
                <Loading /> 
                :
                (error ? [] : days).map(({ day, date, key }) => {
                  const sortedEarnings = ((events && events[key]) || []).sort((a, b) => {
                    return a[sortCriteria] - b[sortCriteria];
                  });
                  return (
                    <Day day={day} date={date} key={key}>
                      {!sortedEarnings.length ? 
                        <Item empty={true} />
                      : null}
                      {sortedEarnings.map(({ ticker, logo, ...details }, i) => {
                        return (
                          <Item
                            width={zoom}
                            ticker={ticker}
                            logo={logo}
                            key={ticker}
                            details={details}
                            setShowDetails={setShowDetails}
                            showDetails={isBeta() && showDetails}
                          />
                        );
                      })}
                    </Day>
                  );
                })
              }
            </Week>
          </Calendar>
        }
      </div>
    </div>
  );
};

