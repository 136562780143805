import checkMobile from "./libs/checkMobile";
const isMobile = checkMobile();

const borderColor = '#eef2fa';

const SortControls = {
  marginTop: 10,
  border: `1px solid ${borderColor}`,
  listStyleType: 'none',
  padding: 10
};

const SortControlsItem = {
  listStyleType: 'none',
  float: 'left',
  padding: 8,
  margin: 3,
  border: `1px solid ${borderColor}`,
  borderRadius: 2,
  cursor: 'pointer',
};

const SelectedSortControlItem = {
  ...SortControlsItem,
  backgroundColor: 'rgb(36 96 132)',
  color: 'white',
};

const SortButton = {
  marginLeft: 10
};

const DateSelect = {
  padding: '10px 10px 10px 10px',
  fontSize: 'large',
  borderColor: '#eef2fa',
};

const SiteLogo = {
  fontFamily: "'Playfair Display', serif",
  color: '#246084',
  paddingBottom: 20,
}

const mobile = {
  imgWidth: 90,
  Item: {
    borderBottom: `1px solid ${borderColor}`,
    listStyleType: 'none',
    marginTop: '10%',
    float: 'left',
    cursor: 'pointer',
  },
  ItemDetails: {
    paddingBottom: 5,
    paddingTop: 5,
    fontSize: 'x-small',
  },
  LogoContainer: {
    height: 70,
    borderBottom: `1px solid ${borderColor}`,
  },
  DayHeader: {
    fontSize: 'x-small',
  },
  SortControls,
  SortControlsItem,
  SelectedSortControlItem,
  SortButton,
  DateSelect: {
    fontSize: 'large',
  },
  SiteLogo: {
    ...SiteLogo,
    fontSize: 35,
  }

};

const desktop = {
  imgWidth: 50,
  Item: {
    width: '50%',
    height: 150,
    borderBottom: `1px solid ${borderColor}`,
    listStyleType: 'none',
    marginTop: '10%',
    float: 'left',
    cursor: 'pointer',
  },
  ItemDetails: {
    paddingBottom: 10,
    paddingTop: 5,
    fontSize: 'small'
  },
  LogoContainer: {
    height: 120,
    borderBottom: `1px solid ${borderColor}`,
  },
  DayHeader: {
    fontSize: 'small',
  },
  SortControls,
  SortControlsItem,
  SelectedSortControlItem,
  SortButton,
  DateSelect,
  SiteLogo: {
    ...SiteLogo,
    fontSize: 100
  },
}

const styles = {
  mobile,
  desktop,
};


export default styles[isMobile ? 'mobile' : 'desktop'];
