import uuid from './uuid';
import newrelic from './newrelic';

const cookieAnonId = document.cookie;
let anonId;

if (!cookieAnonId) {
  anonId = uuid();
  document.cookie = `id=${anonId}`;
} else {
  anonId = cookieAnonId.split('=')[1];
}


newrelic.setCustomAttribute('anonId', anonId);


