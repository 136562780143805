import React from 'react';

export default function Week({ children }) {
  return (
    <div className="clearfix">
      <div className="week-wrapper">
        <div className="week">{children}</div>
      </div>
    </div>
  );
}
