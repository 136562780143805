import moment from 'moment';
import React from 'react';
import styles from './../styles';

export default ({ dates, selectedDate, onWeekSelect, children }) => {

  const handleSelect = (e) => {
    onWeekSelect(moment(e.target.value));
  };

  return (
    <div>
      <div className="page-header">
        <div style={styles.SiteLogo} className="site-logo">
          <span style={{fontWeight: 900 }}>upcoming</span> <span style={{fontWeight: 100 }}>earnings</span>
        </div>
        
        <select style={styles.DateSelect} onChange={handleSelect} value={selectedDate}>
          {dates.map(date => {
            const dateInstance = moment(date);
            const value = dateInstance.format('YYYY-MM-DD');
            return (
              <option key={value} value={value}>{dateInstance.format('MMMM Do, YYYY')}</option>
            );
          })}
        </select>
      </div>
      <div style={{ marginTop: 10 }}>
        {children}
      </div>
    </div>
  );
};
