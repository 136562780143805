
const flags = {};
export const set = (key, value) => {
  flags[key] = value;
};

export const get = (key) => {
  return flags[key];
};

export const isBeta = () => {
  return flags.beta || false;
};